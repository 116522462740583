import React, { useMemo } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { propTypes } from '../../util/types';
import { ListingCard, PaginationLinks } from '../../components';
import { getSessionId, shouldShowFeature } from '../../util/abTesting';
import css from './SearchResultsPanel.module.css';
import { ensureUser } from '../../util/data';

const SearchResultsPanel = props => {
  const {
    className,
    rootClassName,
    listings,
    pagination,
    search,
    setActiveListing,
    currentUser,
  } = props;
  const classes = classNames(rootClassName || css.root, className);

  // Pass null userId for anonymous users
  const isSuperOwnerSortingEnabled = shouldShowFeature(
    'superOwnerSorting',
    currentUser?.id?.uuid || null
  );

  const paginationLinks =
    pagination && pagination.totalPages > 1 ? (
      <PaginationLinks
        className={css.pagination}
        pageName="SearchPage"
        pageSearchParams={search}
        pagination={pagination}
      />
    ) : null;

  // Panel width relative to the viewport
  const panelMediumWidth = 50;
  const panelLargeWidth = 62.5;
  const cardRenderSizes = [
    '(max-width: 767px) 100vw',
    `(max-width: 1023px) ${panelMediumWidth}vw`,
    `(max-width: 1920px) ${panelLargeWidth / 2}vw`,
    `${panelLargeWidth / 3}vw`,
  ].join(', ');

  const sortedListings = useMemo(() => {
    if (!listings || listings.length === 0) return [];

    const prioritised = [];
    const seenUUIDs = new Set();

    const premiumSuperOwner = [];
    const premium = [];
    const superOwner = [];

    listings.forEach((listing, index) => {
      const isPremium = listing.attributes.publicData.subscription === 'premium';
      const author = ensureUser(listing.author);
      const isSO = author.attributes.profile.publicData?.superOwner || false;

      if (isPremium && isSO) {
        premiumSuperOwner.push({ listing, index });
      } else if (isPremium) {
        premium.push({ listing, index });
      } else if (isSO) {
        superOwner.push({ listing, index });
      }
    });

    const sortByDistance = (a, b) => a.index - b.index;
    premiumSuperOwner.sort(sortByDistance);
    premium.sort(sortByDistance);
    superOwner.sort(sortByDistance);

    const groups = [premiumSuperOwner, premium, superOwner];
    for (const group of groups) {
      for (const { listing } of group) {
        if (prioritised.length < 3 && !seenUUIDs.has(listing.id.uuid)) {
          prioritised.push(listing);
          seenUUIDs.add(listing.id.uuid);
        }
      }
    }

    const rest = listings.filter(listing => !seenUUIDs.has(listing.id.uuid));

    return [...prioritised, ...rest];
  }, [listings, isSuperOwnerSortingEnabled]);

  // Combine listings in order based on A/B test
  const finalSortedListings = isSuperOwnerSortingEnabled ? sortedListings : listings ?? [];

  return (
    <div className={classes}>
      <div className={css.listingCards}>
        {finalSortedListings.map(l => (
          <ListingCard
            className={css.listingCard}
            key={l.id.uuid}
            listing={l}
            renderSizes={cardRenderSizes}
            setActiveListing={setActiveListing}
          />
        ))}
        {props.children}
      </div>
      {paginationLinks}
    </div>
  );
};

SearchResultsPanel.defaultProps = {
  children: null,
  className: null,
  listings: [],
  pagination: null,
  rootClassName: null,
  search: null,
  currentUser: null,
};

const { array, node, object, string, bool } = PropTypes;

SearchResultsPanel.propTypes = {
  children: node,
  className: string,
  listings: array,
  pagination: propTypes.pagination,
  rootClassName: string,
  search: object,
  currentUser: propTypes.currentUser,
};

export default SearchResultsPanel;
